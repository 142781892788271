import React from 'react'
import './LoadingDot.css';

export default function LoadingDot() {
  return (
    <div className='container'>
        <div className="loading">...</div>
    </div>
  )
}
