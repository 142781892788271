import React from 'react'
import LoadingDot from './LoadingDot'

function LoadingPage() {
  return (
    <div className='screen-with-nav flex items-center justify-center'>
        <LoadingDot />
    </div>
  )
}

export default LoadingPage