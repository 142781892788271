import { ACTION_SHEET, ACTION_SHEET_RESET } from "../constants";


const INITIAL_STATE = {
    actionSheet_store: false,
};

const Action = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_SHEET_RESET:
            return {...state, ...INITIAL_STATE};
        case ACTION_SHEET:
            return {...state, ...INITIAL_STATE, actionSheet_store: action.payload};
        default:
            return state;
    }
};

export default Action;
