import React, { Suspense, lazy, useEffect, useRef, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
// import LoadingDot from './component/LoadingDot';
import { Provider } from 'react-redux';
import store from './redux/store';
import LoadingPage from './component/LoadingPage';
import localized from './lib/Localization';
// import { useSpring, animated, config } from '@react-spring/web';
// import MainPage from './page/MainPage'
// import BookingForm from './page/BookingForm'
// import Home from './page/Home'
// import ListWorkShop from './page/ListWorkShop'
// import FAQ from './page/FAQ'
// import DetailBengkel from './page/DetailBengkel'

const MainPage = lazy(() => import('./page/MainPage'));
const BookingForm = lazy(() => import('./page/BookingForm'));
const Home = lazy(() => import('./page/Home'));
const ListWorkShop = lazy(() => import('./page/ListWorkShop'));
const FAQ = lazy(() => import('./page/FAQ'));
const DetailBengkel = lazy(() => import('./page/DetailBengkel'));

function App() {

  // const [startPoint, setStartPoint] = useState(0);
  // const [pullChange, setPullChange] = useState();
  
  localized.setLanguage('en')
  // localized.setLanguage('id')

  // useEffect(() => {
  //   window.addEventListener("touchstart", pullStart);
  //   window.addEventListener("touchmove", pull);
  //   window.addEventListener("touchend", endPull);
  //   return () => {
  //     window.removeEventListener("touchstart", pullStart);
  //     window.removeEventListener("touchmove", pull);
  //     window.removeEventListener("touchend", endPull);
  //   };
  // });

  // const initLoading = () => {
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 1000);
  // };

  // const pullStart = (e) => {
  //   const { screenY } = e.targetTouches[0];
  //   setStartPoint(screenY);
  // };

  // const pull = (e) => {
  //   const touch = e.targetTouches[0];
  //   const { screenY } = touch;
  //   let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
  //   setPullChange(pullLength);
  //   console.log({ screenY, startPoint, pullLength, pullChange });
  // };

  // const endPull = (e) => {
  //   setStartPoint(0);
  //   setPullChange(0);
  //   if (pullChange > 220) initLoading();
  // };


  // const loaderStyle = {
  //   backgroundColor: "#fff",
  //   borderRadius: 17,
  //   boxShadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  //     0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  //     0 12.5px 10px rgba(0, 0, 0, 0.035),
  //     0 22.3px 17.9px rgba(0, 0, 0, 0.042),
  //     0 41.8px 33.4px rgba(0, 0, 0, 0.05),
  //     0 100px 80px rgba(0, 0, 0, 0.07)
  //   `,
  //   left: "50%",
  //   height: 34,
  //   position: "absolute",
  //   top: 10,
  //   transform: "translateX(-50%)",
  //   width: 34,
  // };
  // const reloadIconStyle = {
  //   animation: "1s Rotate infinite linear",
  //   left: 7,
  //   position: "relative",
  //   top: -32,
  //   transformOrigin: "50% 49.7%",
  //   width: 20,
  // };
  

  return (
    <div 
      className='flex h-full w-full justify-center m-0 p-0' 
      // style={{ marginTop: pullChange / 3.118 || "" }}
    >
      {/* <animated.div style={loaderStyle}>
        <svg
          style={reloadIconStyle}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          height="100"
          width="100"
        >
          <g fill="none" stroke="#000" strokeLinecap="round">
            <path
              d="M72.158 71.366c-10.8 11.17-27.874 13.42-41.2 5.43-13.324-7.99-19.382-24.112-14.617-38.9 4.767-14.79 19.097-24.34 34.58-23.047C66.405 16.14 78.953 27.937 81.2 43.31l-23.725-.42"
              strokeWidth="8"
            />
            <path d="M85.268 43.312V15.977" strokeWidth="10" />
          </g>
        </svg>
      </animated.div> */}
      <div className='max-w-[600px] w-full h-full bg-gray-50 shadow-xl'>
        <Provider store={store}>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path='/' element={<MainPage />}>
                <Route index element={<Home />} />
                <Route path='/listWorkShop' element={<ListWorkShop />} />
                <Route path='/faq' element={<FAQ />} />
              </Route>
              <Route path='/form' element={<BookingForm />} />
              <Route path='/:id' element={<ListWorkShop />} />
              <Route path='/listWorkShop/:id' element={<DetailBengkel />} />
            </Routes>
          </Suspense>
        </Provider>
      </div>
    </div>
  )
}

export default App